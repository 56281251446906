<template>
  <div class="checkSign"></div>
</template>

<script>
export default {
  name: 'checkSign',
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if (newVal.name == "checkSign") {
          this.checkAppSource(newVal)
        }
      },
    }
  },
  methods: {
    // 判断app来源
    checkAppSource(data) {
      // return
      if (data.query.hasOwnProperty('isZhxtApp')) {
        // 智汇app扫码
        this.$router.replace({
          name: 'signIn',
          query: {
            offlineTeachingId: data.query.id
          }
        })
      } else {
        // 非智汇app扫码
        this.$router.replace({name: 'scanCodeSignProcedure'})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .checkSign {}
</style>
